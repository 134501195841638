#listing_index_item_link {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#listing_index_item_container {
	height: 100%;
	display: flex; 
	align-items: start;
	flex-direction: column;
	justify-content: flex-start;
}

@media screen and (max-width: 420px) {
	#listing_index_item_container {
		padding-left: 16px;
	}
}

#listing_index_item_container:hover {
	cursor: pointer;
}

#listing_index_item_image_wrapper {
	height: 100%;
    width: 100%;
	border-radius: 12px;

}


#listing_index_item_wrapper {
	width: 100%;
	height: 20%;
}

.listing_index_item_textcontent {
	font-family: var(--font-family);
	font-size: 16px;
	color: black;
}

#listing_index_item_state_and_ratings_wrapper {
	display: flex; 
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 4px;
}

#listing_index_item_title_and_state {
	font-weight: 600;
	color: black;
	font-size: 15px;
}

#listing_index_item_milesaway {
	color: #717171;
	margin-top: 4px;
	margin-bottom: 4px;
}

#listing_index_item_daterange {
	color: #717171;
	margin-top: 4px;
	margin-bottom: 4px;
}

#listing_index_item_nightlyprice{
	display: flex; 
	color: black;
}

#listing_index_item_price_only {
	font-weight: 800;
	margin-right: 4px;
	color: black;
}

#listing_index_item_rating {
	min-width: 50px;
}

#RSI_container {
	height: 200px;
	width: 100%; 
	background-color: whitesmoke;
	border-bottom: var(--border-bottom-grey);
	line-height: 1.43;
	box-shadow: var(--box-shadow);
	margin-bottom: 30px;
	text-decoration: none;
	color: black;
	border-radius: 12px 12px 12px 12px;
	display: flex;
}

#RSI_wrapper {
	height: 100%;
	width: 100%;
	display: flex; 
	justify-content: space-between;
}

#RSI_left_wrapper {
	height: 100%;
	width: 60%;
	display: flex;
	flex-direction: column;

}

#RSI_topleft_wrapper {
	height: 50%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: var(--border-bottom-grey);
	position: relative;
	left: 18px;
}

#RSI_bottom_wrapper {
	display: flex;
	justify-content: space-between;
	padding-top: 10px;
}

#RSI_bottomleft_leftside_wrapper {
	color: var(--lsp-secondary-font-color);
	font-size: 20px;
	display: flex; 
	align-items: center;
	height: 100%;
	width: 100%;
	position: relative;
	left: 18px;
}

#RSI_home_description {
	color: var(--lsp-secondary-font-color);
}

#RSI_title {
	font-size: 26px;
	font-weight: 800;
}

#RSI_right_side_wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

#RSI_bottomleft_rightside_wrapper {
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
}

#RSI_right_side_wrapper {
	height: 100%;
	width: 100%;
	display: flex;
}

#RSI_delete_and_edit_wrapper {
	width: 30%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

#RSI_image {
	height: 100%;
	width: 100%;
	border-radius: 0px 12px 12px 0px;
	object-fit: cover;
}

.RSI_button {
	display: flex;
	align-items: center;
	justify-content: center;
	border: var(--main-border);
	height: 20%;
	width: 40%;
	border-radius: 12px 12px 12px 12px;
	position: relative;
	left: 8px;
	color: var(--lsp-secondary-font-color);
}


.RSI_button:hover {
	box-shadow: var(--box-shadow);
	cursor: pointer; 
	color: black;
}

#RSI_edit_button {
	display: absolute;
	position: relative;
	top: 15px;
}

#RSI_delete_button {
	position: relative;
	bottom: 14px;
}

.RSI_link_back_to_listing {
	height: 100%;
	width: 100%;
	border-radius: 12px 12px 12px 12px;
	border: none;
}

#RSI_edit_tool_wrapper {
	background-color: white;
	height: 100%;
	width: 36%;
	height: 580px;
	border: 1px solid rgb(221,221,221);
	box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
	border-radius: 12px;
	padding: 24px;
	margin-bottom: 48px;
	z-index: 2;
	margin-left: 14px;
}
#rsp_container {
	font-family: var(--font-family);
	margin-left: 38px; 
	margin-right: 38px; 
	font-size: 18px;
	margin-left: max(266px, 12%);
	margin-right: min(266px, 12%);
}

#RSP_nav_container {
	position: relative;
	padding-bottom: 40px;
	width: 100%;
	border-bottom: var(--border-bottom-grey);
}

#RSP_top_nav_bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
}

#RSP_logo_wrapper{
	position: relative;
	right: 26px;
	top: 10px;
}

#RSP_search_wrapper {
	display: flex; 
	justify-content: center;
	width: 45%;
}

#RSP_socials_wrapper {
	position: relative; 
	top: 18px;
}

#RSP_settings_options_wrapper {
	position: relative; 
	top: 18px;
}

#rsp_nav_bar_filler {
	margin-bottom: 48px;
}

#rsp_title_wrapper {
	height: 100%;
	width: 100%;
	border-bottom: var(--border-bottom-grey);
	margin-bottom: 18px;
}

#rsp_title {
	font-size: 32px;
	font-weight: 800;
	margin-bottom: 26px;
}
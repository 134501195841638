#logo_container {
	display: flex;
	width: 10%;
	top: 6px;
	position: relative;
	user-select: none;
}

img {
	height: 60px; 
	width: 100px;
}

#app_name {
	position: relative;
	color: #FF385C;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 24px;
	right: 20px;
	top: 18px;
}

@media screen and (max-width: 420px) {
	 #logo_container {
		display: none; 
	}
}
#search_bar_wrapper {
	height: 100%; 
	width: 400px;
}

#search_bar {
	border: 2px solid rgba(0,0,0,0.08);
	box-shadow: 0 3px 3px -2px gray;
	height: 48px;
	position: relative; 
	width: 100%; 
	top: 20px;
	border-radius: 32px;
	cursor: pointer; 
	display: flex; 
	align-items: center;
	justify-content: space-between;
	font-family: 'Roboto', sans-serif;
}

#search_bar:hover {
	box-shadow: 0 4px 3px -2px gray;
}

#SB_input {
	font-family: var(--font-family);
	font-size: 16px;
	position: relative; 
	left: 15px;
	height: 28px;
	border: none; 
	outline: none;
	width: 80%;
}

@media screen and (max-width: 420px) {
	#search_bar { 
		width: 80%;
		
	}

	#search_bar_wrapper {
		width: 100%;
		display: fixed;
		height: 100%;
	}
}
#LSPD_wrapper {
	display: grid;
	grid-template-columns: minmax(0, 50%) repeat(2, 1fr);
	grid-auto-rows: minmax(200px, 240px);
	max-width: 100%;
	max-height: max(200, 200px);
	width: 100%;
	padding-bottom: 10px; 
	margin-top: 24px; 
	gap: 10px;
	border-radius: 12px;
}

#LSPD_item_1 {
	grid-row: 1/3;
	grid-column: 1;
	height: 100%;
	border-radius: 8px 0px 0px 0px;
}

#LSPD_item_1 img {
	border-radius: 12px 0px 0px 12px;
}

#LSPD_item_3 img {
	border-radius: 0px 12px 0px 0px;
} 

#LSPD_item_5 img {
	border-radius: 0px 0px 12px 0px;
} 


.listing_index_item_image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
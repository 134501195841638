#listings_index_container {
	background-color: white;
	height: 100vh;
	width: 100%;
	padding-top: 20px;
	position: relative;
	top: 160px;
	user-select: none;
}

@media screen and (max-width: 420px) {
	#listings_index_container {
		padding-left: 0px !important;
	}
}

#listings_index_wrapper {
	display: grid;
	grid-auto-flow: row dense;
	grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
	grid-auto-rows: 470px;
	width: 100%;
	max-width: 100%;
	column-gap: 24px; 
	row-gap: 40px;
	max-width: 96%;
  padding-bottom: 80px;
  position: relative;
  left: 2%;
}

#listings_index_map_wrapper { 
	display: fixed;
	height: 85vh;
	width: 100%;
	top: 160px;
	left: 0;
}



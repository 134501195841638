#NRI_container {
	height: 200px;
	width: 100%; 
	border-bottom: var(--border-bottom-grey);
	line-height: 1.43;
}

#NRI_wrapper {
	height: 100%;
	width: 100%;
	display: flex; 
	flex-direction: column;
	position: relative; 
	top: 28px;
	margin: 20px;
}

#NRI_notrips {
	font-size: 22px; 
	font-weight: 600; 
	line-height: 26px;
	padding-bottom: 18px;
}

#NRI_sentence {
	color: var(--lsp-secondary-font-colo);
}

#NRI_search_button {
	width: 200px;
	height: 48px;
	border: 2px solid black;
	border-radius: 8px;
	position: relative; 
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}
#NRI_search_button_text {
	text-decoration: none;
	color: black;
}

#NRI_search_button_text:visited {
	text-decoration: none;
	color: black;
}




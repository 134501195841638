#dp_container {
	min-height: 428px;
	width: 640px;
	box-shadow: var(--box-shadow);
	border: var(--main-border);
	border-radius: var(--main-border-radius);
	z-index: 100;
	background-color: white;
	position: relative;
	right: 180px;
	bottom: 110px;

}

#dp_top_options {
	display: flex;
	justify-content: space-between;
}

#dp_top_datepicker_wrapper {
	margin-top: 60px;
}

#dp_top_checkin_checkout {
	position: relative; 
	height: 80%;
	width: 50%;
	top: 10px;
	right: 36px;
}

#dp_margin_bottom {
	padding-bottom: 20px;
}

#dp_top_selectdates {
	font-size: 24px;
	font-weight: 800;
	padding-left: 26px;
	padding-top: 24px;
}

#dp_bottom_row_wrapper {
	display: flex;
	justify-content: flex-end;
}

#dp_close_button {
	border-radius: 8px;
	height: 36px;
	width: 76px;
	border: 1px solid black;
	color: white;
	background-color: #222222;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 32px;
	position: relative;
	bottom: 13px;
}

#dp_close_button:hover {
	cursor: pointer;
}

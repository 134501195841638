#category_filter_container {
	position: fixed; 
	height: 78px;
	width: 100%;
	z-index: 100;
	top: 84px;
	background-color: white;
	z-index: 11;

}

#category_filter_wrapper {
    position: absolute;
	height: 100%; 
	width: 96%;
	display: flex;
	justify-content: space-around;
    left: 2%;
}

@media screen and (max-width: 420px) {
	#category_filter_container {
		position: fixed; 
	}
}
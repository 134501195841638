#login_button {
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: top 0, left 300;
	background-image: radial-gradient( circle at center,#FF385C, #e61e4d 27.5%,#e31c5f 40%,#d70466 57.5%, #bd1e59 75%, #bd1e59 100% );
	width: 100%;
	height: 100%;
	font-size: 16px;
	height: 48px;
	border-radius: 8px; 
	font-weight: 600;
	text-align: center;
	margin-right: 16px;
	color: white;
	font-family: inherit;
	line-height: 20px;
	margin-top: 18px;
	margin-bottom: 18px; 
	border: none;
}

#login_button_hover {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: top 0, left 300;
	background-image: radial-gradient( circle at center,#FF385C, #e61e4d 27.5%,#e31c5f 40%,#d70466 57.5%, #bd1e59 75%, #bd1e59 100% );
	background-size: 200% 200%;
	width: 100%;
	height: 100%;
	font-size: 16px;
	height: 48px;
	border-radius: 8px; 
	font-weight: 600;
	text-align: center;
	margin-right: 16px;
	color: white;
	font-family: inherit;
	line-height: 20px;
	margin-top: 18px;
	margin-bottom: 18px; 
	border: none;
}
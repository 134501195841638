/* TODO Add site wide styles */
:root {
	--font-family: 'Roboto', sans-serif;
	--lsp-secondary-font-color: rgb(113, 113, 113);
	--border-bottom-grey: 1px solid rgba(0,0,0,0.08);
	--box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
	--main-border: 1px solid rgb(221,221,221);
	--main-border-radius: 12px;
}

#main_container {
	display: flex; 
	flex-direction: column;
}

#nav_bar_filler {
	height: 88px;
	width: 100px;
}


#RO_container {
	height: 180px;
	max-width: 1300px; 
}

#RO_stars_RAmount_container {
	display: flex; 
	margin-left: 20px;
	margin-bottom: 20px;
}
#RO_stars_RAmount {
	font-size: 24px;
	padding-right: 4px;
	font-weight: 800;
}

#RO_stars_RAmount_sep {
	font-size: 24px; 
	position: relative; 
	bottom: 4px; 
	font-weight: 800;
}

#RO_amount_of_reviews {
	font-size: 24px;
	padding-left: 4px; 
	font-weight: 800;
}


#RO_each_review_container {
	display: flex;
	flex-direction: row;
}

#RO_each_review_left {
	width: 50%;
}

#RO_each_review_right {
	width: 50%;
}
#image_slider {
    height: 100%;
    width: 100%;
}

#listing_index_slider_wrapper {
    height: 100%;
    width: 100%;
}
.listing_index_slider_image { 
	min-height: 370px;
  max-height: 486px;
	width: 100%;
	border-radius: 12px;
	object-fit: cover;
}

#image_slider_wrapper {
    height: 100%;
    width: 100%;
}


#top_nav_bar_container{
	height: 86px;
	position: fixed; 
	width: 100%;
	background-color: white;
	top: 0; 
	left: 0;
	z-index: 100;
}

#top_nav_bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	width: 100%;
	z-index: 1;
	border-top: 1px solid rgba(0,0,0,0.08);
	border-bottom: 1px solid rgba(0,0,0,0.08);
	height: 100%;
}

@media screen and (max-width: 420px) {
	#top_nav_bar_container {
		position: fixed; 
	}
}

#logo_wrapper {
	height: 100%;
	width: 100%; 
	justify-content: center;
	display: flex;
}

#search_wrapper {
	height: 100%;
	width: 100%;
	justify-content: center;
	display: flex; 
	position: relative;
	left: 46px;
}

#socials_and_nav_wrapper {
	display: flex;
	align-items: center;
	position: relative; 
	right: 64px;
}

#settings_options_wrapper {
	display: flex;
	justify-content: flex-end;
	height: 100%;
	width: 40%;
	padding-right: 18px;
}

a {
	text-decoration: none;
}

@media screen and (max-width: 420px) {
	#socials_and_nav_wrapper {
		display: none;
	}
}
#settings_options_container { 
	height: 84%;
	width: 100px;
	background-color: white;
	display: flex; 
	justify-content: center;
	align-items: center;
}

#settings_options_background {
	border: 2px solid rgba(0,0,0,0.08);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 76px;
	border-radius: 32px;
	padding: 4px;
	padding-top: 4px;
	padding-bottom: 7px;
	cursor: pointer; 
	z-index: 2;
}

#settings_options_background:hover {
	box-shadow: 0 4px 3px -2px gray;
	
}

.fa-bars {
	color: grey;
}

#profile_image {
	height: 32px;
	width: 32px;
	position: relative; 
	left: 10px;
	filter: invert(50%);
}

#settings_options_background:hover #profile_image {
	filter:invert(0%);
}


#settings_options_dropdown {
	position: absolute; 
	background-color: #FFFFFF;
	border: 1px solid rgba(0,0,0,0.08);
	box-shadow: 0 0px 5px rgba(0,0,0,0.2);
	cursor: pointer; 
	border-radius: 21px;
	top: 55px;
	padding: 5px 5px 5px 12px; 
	margin: 0; 
	right: 16px;
	min-height: 130px;
	padding-bottom: 26px;
	width: 200px;
	display: flex;
	flex-direction: column;
	z-index: 10;

}

#settings_options_dropdown ul li {
	flex-grow: 1;
}

.settings_dropdown_item {
	position: relative;
	top: 10px;
	flex-grow: 1;
	height: 43px;
	width: 109%;
	right: 12px;
	font-family: 'Roboto', sans-serif;
	text-indent: 12px;
	text-align: left;
	display: flex;
	align-items: center;
	color: black;
}

.settings_dropdown_item:visited {
	text-decoration: none;
	color: black;
}

.settings_dropdown_item:hover {
	background-color: #F7F7F7;
}

#login_from_loggedout_dropdown {
	text-decoration: none;
	font-weight: 800;
	color: black;
}

#signup_from_loggedout_dropdown {
	color: black;
	border-bottom: 1px solid rgba(0,0,0,0.08);
}

@media screen and (max-width: 420px) {
	#settings_options_container {
		display: none;
	}
}
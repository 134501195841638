#rt_container{
	width: 100%;
	height: 100%;
}

#rt_wrapper {
	background-color: white;
	height: 100%;
	width: 100%;
}

#rt_top_bar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 24px;
}

#rt_reservation_form {
	background-color: white;
}
#rt_top_bar_leftside {
	display: flex;
}

#rt_top_bar_price_and_text {
	font-size: 22px;
	font-weight: 800;
}

#rt_top_bar_night{
	position: relative;
	margin-left: 4px;
	top: 3px;
}

#rt_checkin_checkout_wrapper {
	margin-bottom: 48px;
	position: relative;
	top: 10px;
}

#rt_inputs_wrapper {
	width: 100%;
	height: 100%;
}

#rt_numGuests_wrapper {
	position: relative;
	height: 58px;
	width: 100%;
	padding: 0px;
	border-radius: 0px 0px 8px 8px;
	border: 1px solid #B0B0B0;
	bottom: 13px;
	box-sizing: border-box;
}

#rt_num_guests {
	position: relative;
	top: 16px;
	height: 60%;
	bottom: 12px;
	border-color: #eee;
	border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
	border-bottom-style: hidden;
	outline: none;
}

#rt_top_bar_rightside {
	display: flex;
	font-weight: bold;
	font-size: 16px;
	position: relative;
	top: 3px;
}

#rt_reviews {
	color: rgb(113, 113, 113);
	font-weight: 600;
	text-decoration: underline;
}

#rt_notice_wrapper {
	display: flex;
	justify-content: center;
}

#rt_costs_wrapper {
	margin-top: 36px;
	border-bottom: var(--border-bottom-grey)
}

.rt_cost_wrapper {
	display: flex;
	justify-content: space-between;
	padding-top: 16px;
}

.rt_cost_description {
	text-decoration: underline;
}

#accio_service_fee {
	margin-bottom: 24px;
}

#rt_total_costs_wrapper {
	display: flex;
	justify-content: space-between;
}

.rt_total_cost_item {
	margin-top: 36px;
	font-weight: 800;
	line-height: 20px;
}

#rt_reservation_picker_wrapper {
	position: absolute; 
	z-index: 4;

}

#rt_num_guests {
	font-size: 16px;
}
#create_review_modal {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(34, 34, 34, .2);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	z-index: 2000;
	animation-name: slidein; 
	animation-duration: 0.4s;
	animation-timing-function: ease-out; 
	animation-delay: 0s;
}

@keyframes slidein {
	from {
		bottom: -150vh
	}
	to{bottom: 0}
} 

#create_review_container {
	z-index: 2000;	
	font-family: 'Roboto', sans-serif;
	position: relative;
	font-size: 14px;
	line-height: 1.43;
	border: 1px solid black;
	color: #222;
	padding: 24px;
	min-height: 64px;
	height: 480px;
	width: 700px;
	padding-bottom: 20px;
	border-radius: 12px; 
	background-color: #FFFFFF;
	box-shadow: 0 8px 28px rgba(0,0,0,0.28);
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

#create_review_title {
	font-size: 28px; 
	font-weight: 800;
}

#create_review_subtitle {
	color: rgb(34, 34, 34);
	font-size: 18px;
}

#star_component_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100%;
	position: relative;
	top: 48px;
	left: 4px;
}

#create_review_comment {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	position: relative;
	top: 16px;
}

#create_review_content_wrapper {
	display: flex;	
}

#create_review_textarea_title {
	font-size: 18px; 
	font-weight: 800;
	position: relative;
	left: 32px;
}

#create_review_submit_button_container {
	display: flex;
	justify-content: end;
	width: 100%;
}

#create_review_comment_textarea {
	position: relative; 
	width: 340px;
	height: 300px;
	resize: none;
	box-shadow: var(--box-shadow);
	left: 32px;
	top: 10px;
	font-family: var(--font-family);
	font-size: 16px;
}

#create_review_submit_button_wrapper {
	width: 300px;
	position: relative; 
	right: 5px;
	left: 10px;
	top: 18px;
}

.star_component_rating_count_wrapper {
	display: flex; 
	align-items: center;
}

.star_component_starcount {
	position: relative; 
	left: 5px;
	font-weight: 800;
}

#create_review_description_wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	right: 60px;
	bottom: 42px;

}
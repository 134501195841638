#lsp_container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
}

#lsp_wrapper {
	font-family: var(--font-family);
	font-size: 18px;
	display: flex; 
	flex-direction: column;
	max-width: 1300px;
	min-width: 1100px;
}

@media screen and (max-width: 420px) {
	#lsp_wrapper {
		width: 100%;
		display: fixed;
	}
}

#LSP_nav_container {
	position: relative;
	padding-bottom: 40px;
	width: 100%;
}

#LSP_top_nav_bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 100%;
}

#LSP_logo_wrapper{
	position: relative;
	right: 26px;
	top: 10px;
}

#LSP_search_wrapper {
	display: flex; 
	justify-content: center;
	width: 45%;
}

#LSP_socials_wrapper {
	position: relative; 
	top: 18px;
}

#LSP_settings_options_wrapper {
	position: relative; 
	top: 18px;
}

#lsp_title {
	font-size: 38px;
	font-weight: 800;
	margin-bottom: 26px;
}

#lsp_initial_details {
	display: flex; 
	margin-top: 8px;
	padding-bottom: 20px;
}

#lsp_initial_rating{
 font-weight: bold; 
}

#lsp_initial_details span {
	margin-left: 8px;
	margin-right: 8px;
}
#lsp_rating_reviews_sep {
	position: relative;
	bottom: 4px;
	font-weight: bold;
	padding-left: 4px;
	padding-right: 4px;
}

#lsp_amount_of_reviews_link {
	color: black;
	font-weight: bold;
}

#lsp_amount_of_reviews_link:visited {
	color: black;
}

#lsp_initial_amount_of_reviews {
	font-weight: bold; 
	text-decoration: underline 1px black;
}



#lsp_id_superhost {
	margin-left: 10px;
}

#lsp_id_location_link {
	color: black;
}

#lsp_id_location_link:visited {
	color: black;
}

#lsp_id_location {
	font-weight: 600;
	text-decoration: underline;
}

.fa-medal {
	color: black;
}

#lsp_property_details_container {
	width: 100%;
	min-height: 1000px;
	top: 60px;
	margin-top: 40px;
	display: flex;
	flex-direction: row;
}

#lsp_property_details_wrapper{
	width: 66%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-right: 68px;
}

#lsp_property_details_text_container {
	min-width: 600px;
	width: 100%;
	display: flex;
	align-items: end;
	justify-content: space-between;
	height: 82px;
	padding-bottom: 24px;
	border-bottom: 1px solid rgba(0,0,0,0.08);
}

#lsp_property_details_text_and_beds_container{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 80px;
}

#lsp_property_type_and_hostname {
	font-size: 34px;
	font-weight: 800;
	margin-bottom: 12px;
}


#lsp_property_bedbath_details {
	display: flex;
	color: var(--lsp-secondary-font-color);
}

#lsp_host_profile_container {
	height: 100%;
	width: 86px;
}

#lsp_host_profile_image {
	background-color: grey;
	border-radius: 50%;
	height: 86%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 28px;
	user-select: none;
}

#lsp_host_profile_photo {
	border-radius: 50%;
	width: 76px;
	height: 76px;
}

#lsp_listing_description { 
	width: 100%;
	color: rgb(34, 34, 34);
	padding-top: 32px;
	padding-bottom: 32px;
	border-bottom: 1px solid rgba(0,0,0,0.08);
	line-height: 24px;
	font-size: 16px;
}

#ListingReservationTool_wrapper{
	width: 36%;
	height: 580px;
	border: 1px solid rgb(221,221,221);
	padding: 24px;
	border-radius: 12px;
	box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
	position: sticky;
	top: 200px;
	margin-bottom: 48px;
}

#lsp_reservation_prompt_wrapper {
	border-top: var(--border-bottom-grey);
	height: 80px;
	display: flex;
	align-items: center;
}

#lsp_reservation_prompt_wrapper:hover {
	cursor: pointer; 
	color: #FF385C; 
}

#lsp_gmap_wrapper {
	height: 600px;
	width: 100%;
}

#lsp_reservation_prompt {
	font-size: 24px;
	text-decoration: underline;
	font-weight: 600;
	position: relative;
	left: 20px;
}

#lsp_reversion_prompt:hover {
	cursor: pointer; 
}


#GMaps_Index_Button_Wrapper {
	position: fixed;
	bottom: 6%; 
	left: 50%;
	margin-left: -65px;
	z-index: 10;
}

#GMaps_Index_Button {
	display: flex;
	background-color: rgb(34, 34, 34);
	color: white;
	display: inline-flex;
	cursor: pointer;
	border: 1px solid rgba(0, 0, 0, 0.08) !important;
	align-items: center !important;
	justify-content: center !important;
	box-shadow: none !important;
	padding: 16px 20px !important;
	border-radius: 24px !important;
	border: 1px solid rgba(0, 0, 0, 0.08);
}

#GMaps_Index_Button_Icon_Wrapper {
	margin-left: 4px;
	font-weight: 600;
	font-size: 14px;
}

#GMaps_Index_Button_Text {
	font-weight: 600;
	font-size: 14px;
}

.form_button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	width: 100%;
	height: 100%;
	color: #222;
	font-size: 16px;
	height: 48px;
	border-radius: 8px;
	border-color: #222222;
	border-width: 1px; 
	border-style: solid; 
	font-weight: 600;
	text-align: center;
	margin-right: 16px;
	font-family: inherit;
	line-height: 20px;
	margin-top: 15px;
	margin-bottom: 10px; 
	cursor: pointer;
}

.form_button:hover {
background-color: #EBEBEB;
}
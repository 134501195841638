#IR_container {
	height: 100%;
	width: 100%;
}

#IR_wrapper {
	height: 100%;
	width: 100%;
	display: flex; 
	flex-direction: column; 
	padding: 14px;
}

#IR_top_bar_wrapper {
	height: 48px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 4px;
	margin-bottom: 12px;
}

#IR_reviewer_image {
	height: 40px; 
	width: 40px; 
	border-radius: 50%;
}

#IR_reviewer_image img {
	border-radius: 50%;
	height: 42px;
	width: 42px;
}

#IR_top_bar_leftside {
	height: 100%;
	width: 100%;
	margin-left: 12px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	position: relative;
	top: 8px;

}

#IR_description {
	margin-top: 14px;
	color: rgb(34, 34, 34);
	font-weight: 400; 
	line-height: 28px;
}

.IR_button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: var(--main-border);
	border-radius: 12px;
	padding-left: 20px; 
	padding-right: 20px;
	position: relative; 
	right: 18px;
	bottom: 6px;
}

.IR_button:hover {
	cursor: pointer; 
	background-color: #EBEBEB;
}

#IR_delete_wrapper {
	margin-left: 8px;
}

#IR_buttons_wrapper {
	display: flex;
	height: 100%;
}